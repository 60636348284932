import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { phabletAndBelow } from '@/app/common/style/mixins';
// import { COLOR } from '@/app/common/style/variables';

export const Wrapper = styled.div`
  margin-bottom: 58px;
  text-align: center;

  .btn {
    margin: 0 8px 8px 8px;
  }

  ${phabletAndBelow(css`
    margin-bottom: 32px;
  `)}
`;
