import React, { FC } from 'react';

import { Wrapper } from './styles';
import { ContentfulSection } from '../types.d';
import Button, { ContentfulButton } from '@/components/button/Button';

export interface ButtonsSection extends ContentfulSection {
  buttons: ContentfulButton[];
}

/**
 * Buttons section
 */
const Buttons: FC<ButtonsSection> = ({ buttons }) => (
  <Wrapper>
    {buttons.map((button) => (
      <Button key={button.id} {...button} />
    ))}
  </Wrapper>
);

export default Buttons;
