import { Link as GatsbyLink } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { rem } from '@/app/common/style/functions';
import { phabletAndBelow } from '@/app/common/style/mixins';
import { FONTS } from '@/app/common/style/variables';

const wrapperStyle = css`
  &.btn {
    display: inline-block;
    padding: 16px 100px 0 100px;
  }

  ${phabletAndBelow(css`
    &.btn {
      padding-right: 60px;
      padding-left: 60px;
    }
  `)}
`;

export const Anchor = styled.a`
  ${wrapperStyle}
`;

export const Btn = styled.button`
  ${wrapperStyle}
`;

export const Link = styled(GatsbyLink)`
  ${wrapperStyle}
`;

export const Caption = styled.span`
  display: inline-block;

  > span {
    font-size: ${rem(16)};
    font-weight: ${FONTS.medium};
    line-height: ${rem(24)};
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  > img {
    width: auto;
    height: 14px;

    &.icon-align-left {
      margin-right: 16px;
    }

    &.icon-align-right {
      margin-left: 16px;
    }
  }
`;
