import React, { FC, MouseEvent } from 'react';
import { getSrc } from 'gatsby-plugin-image';
import kebabCase from 'lodash/kebabCase';

import { isAbsoluteUrl } from '@/utils/helpers';
import { Anchor, Btn, Caption, Link } from './styles';
import { ContentfulImage, ContentfulLink } from '@/types';

export interface ContentfulButton {
  id: string;
  caption: string;
  color?: 'Default';
  icon?: ContentfulImage;
  iconAlignment?: 'Left' | 'Right';
  link?: ContentfulLink;
  size?: 'Medium';
}

/**
 * Button component
 */
const Button: FC<ContentfulButton> = ({
  caption,
  icon,
  iconAlignment = `Left`,
  link,
}) => {
  const target = link.target || `_self`;
  const getIcon = (align: string) => {
    if (!icon || kebabCase(iconAlignment) !== align) {
      return <></>;
    }
    return (
      <img
        className={`icon-align-${align}`}
        src={getSrc(icon.gatsbyImageData)}
        alt={icon.title}
        // Dimensions are specified for mobile performance reasons
        width="16"
        height="16"
      />
    );
  };
  const getCaption = () => (
    <Caption>
      {getIcon(`left`)}
      <span>{caption}</span>
      {getIcon(`right`)}
    </Caption>
  );
  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (link.url.startsWith(`#`)) {
      const targetElement = document.getElementById(link.url.substring(1));
      if (target) {
        event.preventDefault();
        event.stopPropagation();
        targetElement.scrollIntoView({
          behavior: `smooth`,
        });
      }
    }
  };

  if (link) {
    if (isAbsoluteUrl(link.url)) {
      return (
        <Anchor
          className="btn link"
          href={link.url}
          target={target}
          title={link.title}
        >
          {getCaption()}
        </Anchor>
      );
    }
    return (
      <Link
        className="btn link"
        to={link.url}
        target={target}
        title={link.title}
        onClick={handleLinkClick}
      >
        {getCaption()}
      </Link>
    );
  }
  return <Btn className="btn">{getCaption()}</Btn>;
};

export default Button;
